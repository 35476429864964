import React from 'react';

import { BlogPostList } from 'components/Blog';
import { SEO } from 'components/UI';
import seo from 'data/seo.json';

export default function Blog() {
	return (
		<>
			<SEO
				title={seo.blogListTitle}
				description={seo.blogListDescription}
				ogTitle={seo.blogListOgTitle}
				ogDescription={seo.blogListOgDescription}
				ogImage={seo.blogListOgImage}
			/>
			<BlogPostList />
		</>
	);
}
